
import { Component, Prop, Vue } from "vue-property-decorator";
import SimpleCardWithList from "../SimpleCardWithList.vue";

@Component({
  components: { SimpleCardWithList },
  filters: {
    getAgentInitials(fullName: string): string {
      return fullName
        .split(" ")
        .map((name) => name[0])
        .join("")
        .slice(0, 2);
    },
  },
})
class CompanyAgentsCard extends Vue {
  @Prop({ required: true }) readonly agents!: [];
}

export default CompanyAgentsCard;
